 .face1 {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 75%;
    top: 35%;
    text-align: center;
    font-size: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #dd0031;
  }
  .face2 {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 62%;
    top: 10%;
    text-align: center;
    font-size: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f06529;
  }
 
  .face3 {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 88%;
    top: 10%;
    text-align: center;
    font-size: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #28a4d9;
  }
  .face4 {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 88%;
    top: 60%;
    text-align: center;
    font-size: 70px;
    display: flex;
    justify-content: center;
    align-items: center;  
    color: #5ed4f4;
  }
  .face5 {   
    position: absolute;
    width: 70px;
    height: 70px;
    left: 62%;
    top: 60%;
    text-align: center;
    font-size: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #efd81d;
  }
  .face6 {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 75%;
    top: 85%;
    text-align: center;
    font-size: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ec4d28;
  }