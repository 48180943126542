.home-page {
    .text-zone {
      position: absolute;
      left: 8%;
      top: 50%;
      transform: translateY(-50%);
      width: 40%;
      max-height: 90%;
    }
  
    h1 {
      color: aliceblue;
      font-size: 76px; //56
      line-height: 73px; //53
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;
    }
    a{
      margin-left: 4rem;
    }
  
    h2 {
      margin-left: 10%;
      color: #838383; 
      margin-top: 20px;
      font-weight: 400;
      font-size: 11px;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeIn 1s 1.8s backwards;
    }
  
    .name{
        color: #5ce1e6;
    }

    

    .flat-button {
      text-align: center;
      color: #5ce1e6;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #5ce1e6;
      margin-top: 25px;
      margin-left: 13%;
      float: left;
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;
  
      &:hover {
        background: #5ce1e6; 
        box-shadow: 5px 10px #272727;
        color: #333;
      }
    }
  }


  @media screen and (max-width: 1200px) {
    .tags,
    .home-page h1:before,
    .home-page h1:after {
      display: none;
    }
  
    .home-page .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
    }
  
    .home-page .flat-button {
      text-align: center;
      float: none;
      display: flex;
      margin: 20px auto 0 auto;
      width: 124px;
    }
  
    .logo-container {
      display: none;
    }
  
  }
