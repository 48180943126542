.linkedin {
    margin-left: 45%;
    padding-top: 4.5%;
    display: flex;
    align-items:end;
    justify-content: center;
    font-size: 25px; //15
    line-height: 16px;
    color: #2f5e93;
  }

  .emailB{
    font-size: 20px;
    font-weight: 100;
  }

  .linkedinB{
    font-size: 13px;
    font-weight: 500;
  }

  .flat-button {
      border-radius: 80px;
      color: #5ce1e6;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #5ce1e6;
      margin-top: 25px;
      margin-left: 13%;
      float: left;
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;
  
      &:hover {
        background: #5ce1e6; 
        box-shadow: 5px 10px #272727;
        color: #333;
      }
  }