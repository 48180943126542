.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
  }

  .about-page,
  .contact-page{ 
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
      }

      h1 {
        font-size: 53px;
        font-family: 'Coolvetica';
        color: #5ce1e6;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        color: aliceblue;
        font-family: sans-serif;
        font-weight: 300;
        max-width: fit-content;
        animation: fadeIn 1s 1.8s backwards;
      }
  
  }
  

  @media screen and (max-width: 1200px) {
    .page {
      position: initial;
    }
  
    .container {
      position: initial;
      height: auto;
      min-height: auto;
  
      &.contact-page,
      &.about-page {
        .text-zone {
          position: initial;
          transform: none;
          width: 100%;
          display: block;
          padding: 20px;
          box-sizing: border-box;
        }
        .Icons {
          display: none;
        }
        
      }
    }
 
  }