h1 {
    top: 15%;
    left: 10%;
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #5ce1e6;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 20px;
    animation: fadeIn 1s 1s backwards;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s 1.8s backwards;
}

.scroll {
    background-color: #2c2c2c;
    margin-top: 10%;
    // margin-left: 20%;
    width: 40%;
    height: 370px;
    overflow-x: hidden;
    overflow-y: auto;
    align-items: center;
    display: block;
    justify-content: center;
    border: 2px solid #393a3a;
    border-radius: 10px;
    box-shadow: 2px 2px 15px #222222;
    padding: 10px 10px 10px 10px;


}

.ticket {
    color: antiquewhite;
    background-color: #3f3f3f;
    font-size: medium;
    width: 90%;
    height: 90%;
    border: 2px solid #2e2e2e;
    box-sizing: border-box;
    display: block;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    padding: 10px 10px 10px 10px;
    margin-top: 2%;
    margin-left: 3%;
    border-radius: 10px;
    overflow-y: auto;
}

.b {
    color: #5ce1e6;
    font-size: small;
    width: 70%;
    font-weight: bold;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #5ce1e6;
    border-radius: 15px;
    margin-top: 5px;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
        background: #5ce1e6;
        box-shadow: 5px 10px #272727;
        color: #333;
    }
}

.head {
    font-size: x-large;
    color: #5ce1e6;
}

::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #434343;
    border-radius: 10px;
}


@media screen and (max-width: 1200px) {
    h1 {
        top: 15%;
        left: 10%;
        font-size: 53px;
        font-family: 'Coolvetica';
        color: #5ce1e6;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 20px;
    }

    .scroll {
        background-color: #2c2c2c;
        margin-top: 10%;
        margin-left: 7%;
        width: 90%;
        height: 470px;
        overflow-x: hidden;
        overflow-y: auto;
        display: block;
        justify-content: center; //flex-start for multiple projects
        border: 2px solid #393a3a;
        border-radius: 10px;
        box-shadow: 2px 2px 15px #222222;
    }

    .ticket {
        color: antiquewhite;
        background-color: #3f3f3f;
        font-size: medium;
        width: 90%;
        height: 90%;
        border: 2px solid #2e2e2e;
        box-sizing: border-box;
        display: block;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
        padding: 10px 10px 10px 10px;
        margin-top: 2%;
        margin-left: 4%;
        border-radius: 10px;
        overflow-y: auto;
    }

    .b {
        color: #5ce1e6;
        font-size: small;
        width: 70%;
        font-weight: bold;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #5ce1e6;
        display: block;
        border-radius: 15px;
        margin-top: 25px;
        animation: fadeInAnimation 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #5ce1e6;
            box-shadow: 5px 10px #272727;
            color: #333;
        }
    }

    .head {
        font-size: x-large;
        color: #5ce1e6;
    }
}