.logo-container {
    width: 400px;
    height: 465px; //609
    position: absolute;
    top: 0;
    bottom: 0;
    left: 60%;
    margin: auto;
    animation: fade 6s infinite;
}

@keyframes fade {
  0%,100% { opacity: 0.3 }
  50% { opacity: 1 }
}